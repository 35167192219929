import { render, staticRenderFns } from "./HelpDetail.vue?vue&type=template&id=75696318&scoped=true&"
import script from "./HelpDetail.vue?vue&type=script&lang=ts&"
export * from "./HelpDetail.vue?vue&type=script&lang=ts&"
import style0 from "./HelpDetail.vue?vue&type=style&index=0&id=75696318&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75696318",
  null
  
)

export default component.exports