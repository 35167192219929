var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-app-bar',[_c('v-app-bar-nav-icon',{on:{"click":function($event){return _vm.back()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$route.params.day))]),_c('v-spacer'),_c('v-app-bar-nav-icon',{on:{"click":function($event){return _vm.back()}}},[_c('v-icon',[_vm._v("mdi-check")])],1)],1),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"}),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('daily-reports.gender'))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('daily-reports.tested'))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('daily-reports.needed'))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('daily-reports.referred'))+" ")])])]),_vm._l((['adults', 'children']),function(age){return _c('tbody',{key:age},_vm._l((['male', 'female', 'unspecified']),function(gender){return _c('tr',{key:gender},[(gender === 'male')?_c('th',{attrs:{"rowspan":"3"}},[_vm._v(" "+_vm._s(_vm.$t('daily-reports.age-group.' + age))+" ")]):_vm._e(),_c('th',[_vm._v(_vm._s(_vm.$t('daily-reports.gender-' + gender)))]),_vm._l((['Tested', 'Needed', 'Referred']),function(type){return _c('td',{key:type},[_c('input',{attrs:{"type":"number","placeholder":"0"},domProps:{"value":_vm.$store.state.dailyReports[
                                    _vm.$route.params.day
                                ] &&
                                _vm.$store.state.dailyReports[
                                    _vm.$route.params.day
                                ][age]['number' + type]
                                    ? _vm.$store.state.dailyReports[
                                          _vm.$route.params.day
                                      ][age]['number' + type][gender]
                                    : undefined},on:{"input":function($event){return _vm.onNumberInput(age, type, gender, $event)}}})])})],2)}),0)})]},proxy:true}])}),_c('v-textarea',{attrs:{"label":_vm.$t('daily-reports.note'),"filled":"","value":_vm.$store.state.dailyReports[_vm.$route.params.day]
                ? _vm.$store.state.dailyReports[_vm.$route.params.day].note
                : undefined},on:{"input":function($event){return _vm.onNoteInput($event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }