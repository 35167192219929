
import Vue from 'vue'
import Component from 'vue-class-component'
import router from '../router'

// Define the component in class-style
@Component
export default class Counter extends Vue {
    go(path: string) {
        router.push(path)
    }
    get resources() {
        console.log(this.$store.state.resources)
        return this.$store.state.resources
            .map((resource) => {
                const resourceContent = resource.content.find(
                    (content) =>
                        content.language.replace('_', '-') === this.$i18n.locale
                )
                if (resourceContent) {
                    return {
                        title: resourceContent.title,
                        text: resourceContent.text,
                        slug: resource.slug,
                    }
                }
            })
            .filter((v) => v)
    }
}
