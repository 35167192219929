import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#00acaa',
                secondary: '#ff0000',
                accent: '#00ff00',
                error: '#b71c1c',
            },
        },
    },
})
