
import gql from 'graphql-tag'
import Vue from 'vue'
import Component from 'vue-class-component'
import router from '../router'

const delay = (callback, delay) => {
    let timeoutHandler
    return (...args) => {
        if (timeoutHandler) {
            clearTimeout(timeoutHandler)
        }
        timeoutHandler = setTimeout(() => {
            callback(...args)
            timeoutHandler = null
        }, delay)
    }
}

@Component
export default class extends Vue {
    isValid = false
    password1 = ''
    password2 = ''
    errorMessage = ''
    pwnage = 0
    checkPasswordPwnage = delay(this._checkPasswordPwnage.bind(this), 500)

    mounted() {
        if (this.$route.params.token) {
            localStorage.setItem('jwt', this.$route.params.token)
        }
    }
    go(path: string) {
        router.push(path)
    }
    change(ev) {
        ev.preventDefault()
    }
    submit(ev) {
        const query = this.$apollo.mutate({
            mutation: gql`
                mutation setNewPassword($password: String) {
                    setNewPassword(password: $password)
                }
            `,
            variables: {
                password: this.password1,
            },
        })
        query
            .then((result) => {
                this.go('/account')
            })
            .catch((error) => {
                if (
                    error &&
                    error.graphQLErrors &&
                    error.graphQLErrors[0].message
                ) {
                    debugger
                    //   this.errorType = error.graphQLErrors[0].message;
                } else {
                    throw error
                }
            })
        ev.preventDefault()
    }
    async onPasswordChange() {
        if (this.password1.length === 0 || this.password2.length === 0) {
            this.errorMessage = ''
            this.pwnage = 0
        } else if (this.password1 === this.password2) {
            this.errorMessage = ''
        } else {
            this.errorMessage = 'Passwords need to be the same'
        }
        if (this.errorMessage) {
            this.pwnage = 0
            return
        }
    }
    async _checkPasswordPwnage() {
        if (crypto.subtle && 'digest' in crypto.subtle) {
            console.log('crypto available')
            const encoder = new TextEncoder()
            const hash = await crypto.subtle.digest(
                'SHA-1',
                encoder.encode(this.password1)
            )
            const hashArray = Array.from(new Uint8Array(hash)) // convert buffer to byte array
            const hashHex = hashArray
                .map((b) => b.toString(16).padStart(2, '0'))
                .join('')
                .toUpperCase()

            console.log(hashHex)
            const response = await fetch(
                'https://api.pwnedpasswords.com/range/' +
                    hashHex.substring(0, 5)
            )
            const text = await response.text()
            const line = text
                .split('\n')
                .find((l) => l.split(':')[0] === hashHex.substring(5))
            this.pwnage = line ? parseInt(line.split(':')[1]) : 0
        }
    }
}
