
import gql from 'graphql-tag'
import Vue from 'vue'
import Component from 'vue-class-component'
import router from '../router'

@Component
export default class extends Vue {
    isValid = false
    email = ''
    errorType = ''
    go(path: string) {
        router.push(path)
    }
    change(ev) {
        ev.preventDefault()
    }
    submit(ev) {
        const query = this.$apollo.mutate({
            mutation: gql`
                mutation ForgotPassword($email: String) {
                    forgotPasswordToken(email: $email)
                }
            `,
            variables: {
                email: this.email,
            },
        })
        query
            .then((result) => {
                this.errorType = 'check-email'
            })
            .catch((error) => {
                if (
                    error &&
                    error.graphQLErrors &&
                    error.graphQLErrors.length > 0
                ) {
                    this.errorType = error.graphQLErrors[0].message
                } else {
                    throw error
                }
            })
        ev.preventDefault()
    }
}
