import Vue from 'vue'
import VueI18n from 'vue-i18n'
import English from '@/../locales/en-US.json'
import Dutch from '@/../locales/nl-NL.json'
import Slovak from '@/../locales/sk-SK.json'

Vue.use(VueI18n)
export default new VueI18n({
    locale: 'en-US',
    fallbackLocale: 'en-US',
    messages: {
        'en-US': English,
        'nl-NL': Dutch,
        'sk-SK': Slovak,
    },
})
