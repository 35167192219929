
import { Vue, Component, Watch } from 'vue-property-decorator'

import router from './router'
import { isLoggedIn } from './vue-apollo'

@Component
export default class extends Vue {
    transitionName = 'slither'
    navigationBar = false
    constructor() {
        super()
    }
    mounted() {
        this.onRoute(this.$route)
    }
    go(path: string) {
        router.push(path)
    }
    @Watch('$route')
    onRoute(to, from?) {
        if (to.meta.noNavigationBar === true) {
            this.navigationBar = false
        } else {
            this.navigationBar = true
        }
        if (!to.meta.noLoginRedirect) {
            if (!isLoggedIn()) {
                this.go('/')
            }
        }
        if (
            this.$store.state.connectionState.onLine &&
            this.$store.state.connectionState.limited === false &&
            this.$store.getters.itemsPendingSync > 0
        ) {
            this.$store.dispatch('sync', this.$apollo)
        }
        this.transitionName =
            (from ? from.meta.position : 0) < to.meta.position
                ? 'slide-left'
                : 'slide-right'
    }
}
