
import Vue from 'vue'
import Component from 'vue-class-component'
import router from '../../router'

@Component
export default class DailyReportsOverview extends Vue {
    go(path: string) {
        router.push(path)
    }
    back() {
        router.back()
    }
    onNumberInput(age, type, gender, ev: InputEvent) {
        this.$store.commit('updateDailyReportField', {
            day: this.$route.params.day,
            age,
            type,
            gender,
            value: parseInt((ev.target as HTMLInputElement).value) || 0,
        })
    }
    onNoteInput(newValue: string) {
        this.$store.commit('updateDailyReportNote', {
            day: this.$route.params.day,
            note: newValue,
        })
    }
}
