
import Vue from 'vue'
import Component from 'vue-class-component'
import router from '../../router'

const startOfLastMonthInUTC = new Date()
startOfLastMonthInUTC.setUTCMonth(startOfLastMonthInUTC.getUTCMonth() - 1)
startOfLastMonthInUTC.setUTCHours(0)
startOfLastMonthInUTC.setUTCMinutes(0)
startOfLastMonthInUTC.setUTCSeconds(0)
startOfLastMonthInUTC.setMilliseconds(0)
startOfLastMonthInUTC.setDate(1)

@Component
export default class DailyReportsOverview extends Vue {
    date = ''
    today = new Date().toISOString()
    startOfLastMonthInUTC = startOfLastMonthInUTC.toISOString()
    go(path: string) {
        router.push(path)
    }
    back() {
        router.back()
    }
    get pickerDate() {
        return this.$route.params.month
    }
    set pickerDate(newVal) {
        this.$router.replace('/account/daily-reports/' + newVal)
    }
    functionEvents(date) {
        const startOfTodayInUTC = new Date()
        startOfTodayInUTC.setUTCHours(0)
        startOfTodayInUTC.setUTCMinutes(0)
        startOfTodayInUTC.setUTCSeconds(0)
        if (new Date(date) > startOfTodayInUTC) {
            return false
        }

        if (new Date(date) < startOfLastMonthInUTC) {
            return false
        }
        if (this.$store.state.dailyReports[date]) {
            if (this.$store.state.dailyReports[date].needsSync) {
                return ['orange']
            }
            return ['primary']
        }
        if (this.$store.state.distributedInventoryEventAggregateByDay[date]) {
            return ['secondary']
        }

        return undefined
    }
    onClick() {
        router.push('/account/daily-report/' + this.date)
    }
}
