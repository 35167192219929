
import { onLogout } from '@/vue-apollo'
import Vue from 'vue'
import Component from 'vue-class-component'
import { mapFields } from 'vuex-map-fields'
import router from '../router'

@Component({
    computed: {
        ...mapFields(['authentication.email']),
        isOffline() {
            return !this.$store.state.connectionState.onLine
        },
    },
})
export default class extends Vue {
    relativeTime(date) {
        const rtf = new (Intl as any).RelativeTimeFormat(this.$i18n.locale, {
            style: 'long',
        })
        console.log(date)
        const difference = Math.round(
            (Date.now() - new Date(date).getTime()) / 1000
        )
        if (difference < 60) {
            return rtf.format(difference * -1, 'seconds')
        }
        if (difference < 3600) {
            return rtf.format(Math.round(difference / 60) * -1, 'minutes')
        }
        if (difference < 3600) {
            return rtf.format(Math.round(difference / 60) * -1, 'minutes')
        }
        if (difference < 86400) {
            return rtf.format(Math.round(difference / 60 / 60) * -1, 'hours')
        }
        return rtf.format(Math.round(difference / 60 / 60 / 24) * -1, 'days')
    }
    go(path: string) {
        router.push(path)
    }
    logout() {
        // onLogout(apoo);
        onLogout(this.$apolloProvider.defaultClient)
        this.$store.commit('reset')
        this.go('/')
    }
    get selectedInventoryName() {
        console.log(
            this.$store.state.inventories,
            this.$store.state.selectedInventory
        )
        return this.$store.state.inventories.find(
            (inventory) => inventory._id === this.$store.state.selectedInventory
        )?.name
    }
}
