
import Vue from 'vue'
import Component from 'vue-class-component'
import router from '../router'
import { mapFields } from 'vuex-map-fields'
import gql from 'graphql-tag'
import { onLogin } from '@/vue-apollo'

// Define the component in class-style
@Component({
    computed: {
        ...mapFields([
            'authentication.email',
            'authentication.password',
            'authentication.jwt',
        ]),
    },
})
export default class LogIn extends Vue {
    isValid = false
    isRequired = [(v: any) => !!v || 'Field is required']
    errorType = ''

    mounted() {
        if (localStorage.jwt) {
            this.go('/distribute')
        }
    }
    login(ev) {
        ev.preventDefault()

        console.log('start login')
        console.log(this.$store.state.authentication.email)
        const query = this.$apollo.query({
            query: gql`
                query LogIn($email: String, $password: String) {
                    authenticationToken(email: $email, password: $password)
                }
            `,
            variables: {
                email: this.$store.state.authentication.email,
                password: this.$store.state.authentication.password,
            },
        })
        query
            .then((result) => {
                if (result.data.authenticationToken) {
                    onLogin(
                        this.$apolloProvider.defaultClient,
                        result.data.authenticationToken
                    )
                    // localStorage.jwt = result.data.authenticationToken;
                    this.go('/account/sync')
                } else {
                    console.log(result)
                }
            })
            .catch((error) => {
                console.error(error)
                if (
                    error &&
                    error.graphQLErrors &&
                    error.graphQLErrors.length &&
                    error.graphQLErrors[0].message
                ) {
                    this.errorType = error.graphQLErrors[0].message
                } else {
                    throw error
                }
            })
    }
    go(path: string) {
        router.push(path)
    }
}
