// alert('hai');
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './languages'
import vuetify from './plugins/vuetify'
import { sync } from 'vuex-router-sync'
import { createProvider } from './vue-apollo'
import connection from './services/connection'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

import '@babel/runtime/regenerator'

Sentry.init({
    Vue,
    dsn:
        'https://5b9abed1a83f40e4beb7a8cf9a5a2ef6@o1085633.ingest.sentry.io/6096655',
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ['localhost', 'app.dotglasses.org', /^\//],
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
})

// import connection from './services/connection'
// import 'fontsource-roboto/latin.css'
Vue.config.productionTip = false
sync(store, router)
i18n.locale = store.state.language
connection.syncToStore(store)

new Vue({
    router,
    store,
    vuetify,
    i18n,
    apolloProvider: createProvider(),
    render: (h) => h(App),
}).$mount('#app')
