
import { LensType, LensTypeInfo, StoreType, AgeCategory } from '@/store'
import Vue from 'vue'
import Component from 'vue-class-component'
import { mapFields } from 'vuex-map-fields'

@Component({
    data: () => {
        return {
            LensType,
        }
    },
    computed: {
        ...mapFields([
            'distribution.step',
            'distribution.name',
            'distribution.age',
            'distribution.gender',
            'distribution.glassType',
            'distribution.distributionType',
            'distribution.leftGlass',
            'distribution.rightGlass',
            'distribution.lensType',
            'distribution.ipd',
            'distribution.occupation',
            'distribution.phoneNumber',
            'distribution.note',
            'distributedGlasses',
        ]),
    },
})
export default class extends Vue {
    consumerInformationStepValid = false
    glassesInformationStepValid = false

    isRequired = [(v: any) => !!v || v === 0 || 'Field is required']

    updateGlassType() {
        const data = (this as any) as StoreType['distribution']
        if (data.glassType === undefined || data.glassType === null) {
            if (data.age < 18) {
                data.glassType = AgeCategory.Child
            } else {
                data.glassType = AgeCategory.Adult
            }
            this.updateLensTypeFromGlassType()
        }
    }
    resetParts() {
        if ((this as any).distributionType === 'partial') {
            ;(this as any).leftGlass = undefined
            ;(this as any).rightGlass = undefined
            ;(this as any).ipd = undefined
            ;(this as any).glassType = undefined
        }
    }
    updateLensTypeFromGlassType() {
        const data = (this as any) as StoreType['distribution']
        if (
            data.lensType === undefined ||
            data.lensType === null ||
            data.glassType !== LensTypeInfo[data.lensType].ageCategory
        ) {
            if (data.glassType === AgeCategory.Adult) {
                data.lensType = LensType.AdultNormal
            } else {
                data.lensType = LensType.ChildNormal
            }
        }
    }
    distribute() {
        this.$store.commit('commitDistributedGlassesStep1')
        ;(this.$refs.formStepOne as any).reset()
        ;(this.$refs.formStepTwo as any).reset()
        // the reset somehow interferes with the assignment of the new values... so waiting one tick 🤷‍♀️😕
        this.$nextTick().then(() => {
            this.$store.commit('commitDistributedGlassesStep2')
            ;(this as any).step = 1
        })
        if (
            this.$store.state.connectionState.onLine &&
            this.$store.state.connectionState.limited === false
        ) {
            this.$store.dispatch('sync', this.$apollo)
        }
    }
}
