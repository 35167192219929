
// import Vue from 'vue'
// import Component from 'vue-class-component'
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Counter extends Vue {
    resource: any
    beforeCreate() {
        this.resource = this.$store.state.resources
            .find((resource) => resource.slug === this.$route.params.slug)
            .content.find(
                (resourceContent) =>
                    resourceContent.language.replace('_', '-') ===
                    this.$i18n.locale
            )
    }
}
