
import Vue from 'vue'
import Component from 'vue-class-component'
import router from '../router'

@Component
export default class SyncComponent extends Vue {
    go(path: string) {
        router.push(path)
    }
    mounted() {
        this.$store
            .dispatch('sync', this.$apollo)
            .then(() => this.go('/account'))
            .catch((error) => {
                console.error('sync failed', error)
                alert('Sync failed. Please try again later.')
                this.go('/account')
            })
    }
}
