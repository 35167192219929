
import Vue from 'vue'
import Component from 'vue-class-component'
import router from '../router'
import { mapFields } from 'vuex-map-fields'

// Define the component in class-style
@Component({ computed: mapFields(['selectedInventory']) })
export default class Counter extends Vue {
    go(path: string) {
        router.push(path)
    }
    get inventories() {
        return this.$store.state.inventories
    }
}
