import { sync } from '@/services/sync'
import Vue from 'vue'
import Vuex from 'vuex'

import { getField, updateField } from 'vuex-map-fields'
import VuexPersistence from 'vuex-persist'
import { dateFormat } from './date-util'

Vue.use(Vuex)

export enum AgeCategory {
    Adult = 'adult',
    Child = 'child',
}
export enum LensType {
    AdultNormal,
    AdultPhotochromatic,
    AdultSunglasses,
    ChildNormal,
}
export const LensTypeInfo: Record<
    LensType,
    {
        suffix: string
        ageCategory: AgeCategory
    }
> = {
    [LensType.AdultNormal]: {
        ageCategory: AgeCategory.Adult,
        suffix: '',
    },
    [LensType.AdultPhotochromatic]: {
        ageCategory: AgeCategory.Adult,
        suffix: '-P',
    },
    [LensType.AdultSunglasses]: {
        ageCategory: AgeCategory.Adult,
        suffix: '-SG',
    },
    [LensType.ChildNormal]: {
        ageCategory: AgeCategory.Child,
        suffix: '',
    },
}

const initialDistribution = {
    step: 1,
    name: undefined,
    age: (undefined as unknown) as number,
    gender: undefined,
    occupation: undefined,
    phoneNumber: undefined,
    ipd: undefined,
    lensType: (undefined as unknown) as LensType,
    distributionType: 'complete',
    leftGlass: (undefined as unknown) as string,
    rightGlass: (undefined as unknown) as string,
    glassType: (undefined as unknown) as AgeCategory,
    note: undefined,
}

const state = {
    language: 'en-US',
    distribution: initialDistribution,
    authentication: {
        email: undefined,
        password: undefined,
        jwt: undefined,
    },
    distributed: [] as (typeof initialDistribution & {
        date: Date
        inventory: any
    })[],
    distributedGlasses: false,
    sync: {
        lastSyncDate: new Date(0),
    },
    connectionState: {
        limited: true,
        onLine: false,
    },
    selectedInventory: (undefined as unknown) as string,
    inventories: [] as {
        name: string
        _id: string
    }[],
    resources: [],
    distributedInventoryEventAggregateByDay: {} as Record<string, number>,
    dailyReports: {} as Record<
        string,
        {
            needsSync?: boolean
            note: string
            adults: {
                numberTested: {
                    male: number | undefined
                    female: number | undefined
                    unspecified: number | undefined
                }
                numberNeeded: {
                    male: number | undefined
                    female: number | undefined
                    unspecified: number | undefined
                }
                numberReferred: {
                    male: number | undefined
                    female: number | undefined
                    unspecified: number | undefined
                }
            }
            children: {
                numberTested: {
                    male: number | undefined
                    female: number | undefined
                    unspecified: number | undefined
                }
                numberNeeded: {
                    male: number | undefined
                    female: number | undefined
                    unspecified: number | undefined
                }
                numberReferred: {
                    male: number | undefined
                    female: number | undefined
                    unspecified: number | undefined
                }
            }
        }
    >,
}

export type StoreType = typeof state
const vuexLocal = new VuexPersistence<typeof state>({
    storage: window.localStorage,
})
const originalState = JSON.parse(
    JSON.stringify(state, (k, v) => (v === undefined ? null : v))
)

export default new Vuex.Store({
    state,
    getters: {
        getField,
        getDailyReportsPendingSync(state) {
            return Object.values(state.dailyReports).filter(
                (report) => report.needsSync
            ).length
        },
        itemsPendingSync(state, getters) {
            return getters.getDailyReportsPendingSync + state.distributed.length
        },
        getPendingReportCount(state) {
            const startOfTodayInUTC = new Date()
            startOfTodayInUTC.setUTCHours(0)
            startOfTodayInUTC.setUTCMinutes(0)
            startOfTodayInUTC.setUTCSeconds(0)

            const startOfLastMonthInUTC = new Date()
            startOfLastMonthInUTC.setUTCMonth(
                startOfLastMonthInUTC.getUTCMonth() - 1
            )
            startOfLastMonthInUTC.setUTCHours(0)
            startOfLastMonthInUTC.setUTCMinutes(0)
            startOfLastMonthInUTC.setUTCSeconds(0)
            startOfLastMonthInUTC.setMilliseconds(0)
            startOfLastMonthInUTC.setDate(1)

            let pending = 0
            for (
                startOfLastMonthInUTC;
                startOfLastMonthInUTC <= startOfTodayInUTC;
                startOfLastMonthInUTC.setDate(
                    startOfLastMonthInUTC.getDate() + 1
                )
            ) {
                const date = dateFormat(startOfLastMonthInUTC)
                const hasDistributed =
                    state.distributedInventoryEventAggregateByDay[date]
                const hasFiled = state.dailyReports[date]
                if (hasDistributed && !hasFiled) {
                    pending++
                }
            }
            return pending
        },
    },
    mutations: {
        updateField,
        setResources(state, payload) {
            state.resources = payload
            console.log(state, payload)
        },
        setInventories(state, payload) {
            state.inventories = payload
        },
        commitDistributedGlassesStep1(state) {
            state.distributed.push({
                ...state.distribution,
                date: new Date(),
                inventory: state.selectedInventory,
            })
            state.distributedGlasses = true
        },
        commitDistributedGlassesStep2(state) {
            for (const key of Object.keys(originalState.distribution)) {
                state.distribution[key] = originalState.distribution[key]
            }
        },

        reset(state) {
            for (const key of Object.keys(originalState)) {
                if (['language', 'connectionState'].includes(key)) {
                    continue
                }
                state[key] =
                    originalState[key] === null ? undefined : originalState[key]
            }
        },
        updateSyncDate(state) {
            state.sync.lastSyncDate = new Date()
        },
        setConnectionState(state, payload) {
            state.connectionState = payload
        },
        setLanguage(state, payload) {
            state.language = payload
        },
        createNoteIfDoesNotExist(state, day) {
            if (!state.dailyReports[day]) {
                Vue.set(state.dailyReports, day, {
                    note: '',
                    adults: {
                        numberTested: {
                            female: undefined,
                            male: undefined,
                            unspecified: undefined,
                        },
                        numberNeeded: {
                            female: undefined,
                            male: undefined,
                            unspecified: undefined,
                        },
                        numberReferred: {
                            female: undefined,
                            male: undefined,
                            unspecified: undefined,
                        },
                    },
                    children: {
                        numberTested: {
                            female: undefined,
                            male: undefined,
                            unspecified: undefined,
                        },
                        numberNeeded: {
                            female: undefined,
                            male: undefined,
                            unspecified: undefined,
                        },
                        numberReferred: {
                            female: undefined,
                            male: undefined,
                            unspecified: undefined,
                        },
                    },
                })
            }
        },
        updateDailyReportNote(
            state,
            payload: {
                day: string
                note: string
            }
        ) {
            ;(this as any).commit('createNoteIfDoesNotExist', payload.day)
            Vue.set(state.dailyReports[payload.day], 'needsSync', true)
            Vue.set(state.dailyReports[payload.day], 'note', payload.note)
        },
        updateDailyReportField(
            state,
            payload: {
                day: string
                age: 'adults' | 'children'
                type: 'Tested' | 'Needed' | 'Referred'
                gender: 'male' | 'female' | 'unspecified'
                value?: number
            }
        ) {
            ;(this as any).commit('createNoteIfDoesNotExist', payload.day)
            if (payload.value === 0) {
                payload.value = undefined
            }
            Vue.set(state.dailyReports[payload.day], 'needsSync', true)
            // state.dailyReportsPendingSync = Object.values(
            //     state.dailyReports
            // ).filter((report) => report.needsSync).length
            Vue.set(
                state.dailyReports[payload.day][payload.age][
                    'number' + payload.type
                ],
                payload.gender,
                payload.value
            )
        },
    },
    actions: {
        async sync(store, $apollo) {
            return (sync as any)(store, $apollo)
        },
    },
    modules: {},
    plugins: [vuexLocal.plugin],
})
