import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Sync from '../views/Sync.vue'
import Home from '../views/Home.vue'
import Distribute from '../views/Distribute.vue'
import Account from '../views/Account.vue'
import Help from '../views/Help.vue'
import LogIn from '../views/LogIn.vue'
import Languages from '../views/Languages.vue'
import Inventories from '../views/Inventories.vue'
import ChangePassword from '../views/ChangePassword.vue'
import HelpDetail from '../views/HelpDetail.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import GoToAdmin from '../views/GoToAdmin.vue'
import DailyReportsOverview from '../views/daily-reports/Overview.vue'
import DailyReport from '../views/daily-reports/DailyReport.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    { path: '/', redirect: '/login' },
    {
        path: '/account/sync',
        name: 'Sync',
        component: Sync,
        meta: {
            position: -1,
            noNavigationBar: true,
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: LogIn,
        meta: {
            position: -2,
            noNavigationBar: true,
            noLoginRedirect: true,
        },
    },
    {
        path: '/forgot-password',
        name: 'Reset Password',
        component: ForgotPassword,
        meta: {
            position: -1,
            noNavigationBar: true,
            noLoginRedirect: true,
        },
    },
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            position: 0,
        },
    },
    {
        path: '/distribute',
        name: 'Distribute',
        component: Distribute,
        meta: {
            position: 1,
        },
    },
    {
        path: '/account',
        name: 'Account',
        component: Account,
        meta: {
            position: 2,
        },
    },
    {
        path: '/account/inventories',
        name: 'Inventories',
        component: Inventories,
        meta: {
            position: 3,
        },
    },
    {
        path: '/account/admin-sso',
        name: 'AdminInterface',
        component: GoToAdmin,
        meta: {
            position: 3,
        },
    },
    {
        path: '/help',
        name: 'Help',
        component: Help,
        meta: {
            position: 1.5,
        },
    },
    {
        path: '/help/:slug',
        name: 'Help detail',
        component: HelpDetail,
        meta: {
            position: 4,
        },
    },
    {
        path: '/account/languages',
        name: 'Languages',
        component: Languages,
        meta: {
            position: 3,
        },
    },
    {
        path: '/account/change-password/:token?',
        name: 'Change Password',
        component: ChangePassword,
        meta: {
            position: 3,
        },
    },
    {
        path: '/account/daily-reports/:month?',
        name: 'Daily Reports',
        component: DailyReportsOverview,
        meta: {
            position: 3,
        },
    },
    {
        path: '/account/daily-report/:day',
        name: 'Daily Rerport',
        component: DailyReport,
        meta: {
            position: 4,
        },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router
