export default new (class Connection {
    syncToStore(store) {
        const connection =
            (navigator as any).connection ||
            (navigator as any).mozConnection ||
            (navigator as any).webkitConnection
        function updateConnectionStatus() {
            let limited
            if (!connection) {
                // iOS
                limited = false
            } else if ('type' in connection) {
                const unlimited = ['ethernet', 'wifi']
                if (unlimited.includes(connection.type)) {
                    limited = false
                } else {
                    limited = true
                }
            } else if ('effectiveType' in connection) {
                if (
                    connection.effectiveType === '3g' ||
                    connection.effectiveType === '4g'
                ) {
                    limited = false
                } else {
                    limited = true
                }
            } else {
                // limited = false
            }

            store.commit('setConnectionState', {
                onLine: navigator.onLine,
                limited,
            })
        }
        updateConnectionStatus()
        window.addEventListener('offline', updateConnectionStatus)
        window.addEventListener('online', updateConnectionStatus)

        if (connection) {
            connection.addEventListener('change', updateConnectionStatus)
        }
    }
})()
