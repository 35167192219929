import gql from 'graphql-tag'
import { FetchResult } from 'apollo-link'
import Store, { AgeCategory, LensTypeInfo } from '../store/index'
import { DollarApollo } from 'vue-apollo/types/vue-apollo'
import { ActionContext } from 'vuex'
import { dateFormat } from '@/store/date-util'

export async function sync(
    store: ActionContext<typeof Store['state'], typeof Store['state']>,
    $apollo: DollarApollo<any>
) {
    await $apollo.getClient().clearStore()
    if (
        Object.values(store.state.dailyReports).some(
            (report) => report.needsSync
        )
    ) {
        await $apollo.mutate({
            mutation: gql`
                mutation C($dailyReports: [CreateManyDailyReportInput!]!) {
                    dailyReportCreateOrUpdate(records: $dailyReports) {
                        created
                        updated
                    }
                }
            `,
            variables: {
                dailyReports: Object.entries(store.state.dailyReports).map(
                    ([date, data]) => ({
                        date,
                        ...data,
                        needsSync: undefined,
                    })
                ),
            },
        })
        // store.state.dailyReportsPendingSync = 0
    }
    const twoMonthsAgo = new Date()
    twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2)
    const queryResult = await $apollo.query({
        query: gql`
                    query A {
                        resources: resourceMany {
                            title
                            slug
                            content {
                                language
                                title
                                text
                            }
                        }
                        inventoryMany {
                            _id
                            name
                        }
                        skus: SKUMany {
                            code
                            _id
                        }
                        dailyReportMany(
                            filter: {
                                _operators: { date: { gte: "${dateFormat(
                                    twoMonthsAgo
                                )}" } }
                            }
                        ) {
                            date
                            createdBy
                            adults {
                                numberTested {
                                    male
                                    female
                                    unspecified
                                }
                                numberReferred {
                                    male
                                    female
                                    unspecified
                                }
                                numberNeeded {
                                    male
                                    female
                                    unspecified
                                }
                            }
                            children {
                                numberTested {
                                    male
                                    female
                                    unspecified
                                }
                                numberReferred {
                                    male
                                    female
                                    unspecified
                                }
                                numberNeeded {
                                    male
                                    female
                                    unspecified
                                }
                            }
                            note
                        }
                        distributedInventoryEventAggregateByDay {
                            date
                            count
                        }
                    }
                `,
    })
    store.state.distributedInventoryEventAggregateByDay = queryResult.data.distributedInventoryEventAggregateByDay.reduce(
        (prev, cur) => {
            prev[cur.date] = cur.count
            return prev
        },
        {}
    )
    store.state.dailyReports = queryResult.data.dailyReportMany.reduce(
        (prev, cur) => {
            prev[dateFormat(new Date(cur.date))] = {
                adults: {
                    numberTested: {
                        male: cur.adults.numberTested.male,
                        female: cur.adults.numberTested.female,
                        unspecified: cur.adults.numberTested.unspecified,
                    },
                    numberReferred: {
                        male: cur.adults.numberReferred.male,
                        female: cur.adults.numberReferred.female,
                        unspecified: cur.adults.numberReferred.unspecified,
                    },
                    numberNeeded: {
                        male: cur.adults.numberNeeded.male,
                        female: cur.adults.numberNeeded.female,
                        unspecified: cur.adults.numberNeeded.unspecified,
                    },
                },
                children: {
                    numberTested: {
                        male: cur.children.numberTested.male,
                        female: cur.children.numberTested.female,
                        unspecified: cur.children.numberTested.unspecified,
                    },
                    numberReferred: {
                        male: cur.children.numberReferred.male,
                        female: cur.children.numberReferred.female,
                        unspecified: cur.children.numberReferred.unspecified,
                    },
                    numberNeeded: {
                        male: cur.children.numberNeeded.male,
                        female: cur.children.numberNeeded.female,
                        unspecified: cur.children.numberNeeded.unspecified,
                    },
                },
                note: cur.note,
            }
            return prev
        },
        {}
    )
    let mutationResult:
        | FetchResult<any, Record<string, any>, Record<string, any>>
        | undefined = undefined
    if (store.state.distributed.length > 0) {
        const skus: {
            code: string
            _id: string
        }[] = queryResult.data.skus
        const distributionEvents = store.state.distributed.map(
            (distributedGlasses) => {
                const framesetSkuCode =
                    distributedGlasses.glassType &&
                    process.env[
                        distributedGlasses.glassType === AgeCategory.Adult
                            ? 'VUE_APP_ADULT_FRAMESET'
                            : 'VUE_APP_CHILD_FRAMESET'
                    ]
                const framesetSku = skus.find(
                    (sku) => sku.code === framesetSkuCode
                )
                const lensSkuCodeTemplate =
                    typeof distributedGlasses.lensType === 'number'
                        ? process.env[
                              LensTypeInfo[distributedGlasses.lensType]
                                  .ageCategory === AgeCategory.Adult
                                  ? 'VUE_APP_ADULT_LENS'
                                  : 'VUE_APP_CHILD_LENS'
                          ]
                        : undefined
                const leftLensSkuCode =
                    distributedGlasses.leftGlass &&
                    lensSkuCodeTemplate?.replace(
                        '{code}',
                        distributedGlasses.leftGlass
                    ) + LensTypeInfo[distributedGlasses.lensType].suffix
                const leftLensSku = skus.find(
                    (sku) => sku.code === leftLensSkuCode
                )
                const rightLensSkuCode =
                    distributedGlasses.rightGlass &&
                    lensSkuCodeTemplate?.replace(
                        '{code}',
                        distributedGlasses.rightGlass
                    ) + LensTypeInfo[distributedGlasses.lensType].suffix
                const rightLensSku = skus.find(
                    (sku) => sku.code === rightLensSkuCode
                )
                class ConfigurationError extends Error {
                    constructor(message, protected details) {
                        super(message)
                        console.error(details)
                    }
                }

                const movements = [
                    framesetSku?._id && {
                        SKU: framesetSku._id,
                        quantity: 1,
                    },
                    leftLensSku?._id && {
                        SKU: leftLensSku._id,
                        quantity: 1,
                    },
                    rightLensSku?._id && {
                        SKU: rightLensSku._id,
                        quantity: 1,
                    },
                ].filter((v) => v)

                if (
                    (distributedGlasses.distributionType === 'partial' &&
                        movements.length === 0) ||
                    (distributedGlasses.distributionType === 'complete' &&
                        movements.length < 3)
                ) {
                    console.info(
                        framesetSkuCode,
                        leftLensSkuCode,
                        rightLensSkuCode
                    )
                    console.info(
                        JSON.stringify(skus),
                        JSON.stringify(movements),
                        JSON.stringify(distributedGlasses)
                    )
                    alert(
                        'Configuration error. Please contact your administrator.'
                    )
                    throw new ConfigurationError('config-error', {
                        framesetSku,
                        leftLensSku,
                        rightLensSku,
                    })
                }

                return {
                    inventory: distributedGlasses.inventory,
                    date: distributedGlasses.date, // incorrect
                    shortDescription: distributedGlasses.note, // incorrect
                    movements,
                    consumer: {
                        name: distributedGlasses.name,
                        age: distributedGlasses.age,
                        gender: distributedGlasses.gender,
                        interpupillaryDistance: distributedGlasses.ipd,
                        occupation: distributedGlasses.occupation,
                        phoneNumber: distributedGlasses.phoneNumber,
                    },
                }
            }
        )
        mutationResult = await $apollo.mutate({
            mutation: gql`
                mutation B(
                    $distributionEvents: [CreateManyDistributedInventoryEventInput!]!
                ) {
                    distributedInventoryEventCreateMany(
                        records: $distributionEvents
                    ) {
                        recordIds
                    }
                }
            `,
            variables: {
                distributionEvents,
            },
        })
    }
    store.commit('setResources', queryResult.data.resources)
    store.commit('setInventories', queryResult.data.inventoryMany)
    if (
        (!store.state.selectedInventory ||
            !store.state.inventories.find(
                (inventory) => inventory._id === store.state.selectedInventory
            )) &&
        queryResult.data.inventoryMany.length > 0
    ) {
        store.commit('updateField', {
            path: 'selectedInventory',
            value: queryResult.data.inventoryMany[0]._id,
        })
    }
    if (
        mutationResult &&
        mutationResult.data.distributedInventoryEventCreateMany.recordIds
            .length > 0
    ) {
        store.commit('updateField', {
            path: 'distributed',
            value: [],
        })
    }
    store.commit('updateSyncDate')
}
