
import { Vue, Component } from 'vue-property-decorator'
import gql from 'graphql-tag'
import router from '../router'

@Component
export default class Counter extends Vue {
    resource: any
    beforeCreate() {
        this.$apollo
            .query({
                query: gql`
                    {
                        ssoToken
                    }
                `,
            })
            .then((result) => {
                window.open(
                    process.env.VUE_APP_ADMIN_SSO + '?' + result.data.ssoToken
                )
                router.back()
            })
    }
}
