
import Vue from 'vue'
import Component from 'vue-class-component'
import router from '../router'

// Define the component in class-style
@Component
export default class Counter extends Vue {
    go(path: string) {
        router.push(path)
    }
    changeLanguage(locale) {
        this.$i18n.locale = locale
        this.$store.commit('setLanguage', locale)
        router.push('/account')
    }
    getFlagEmoji(countryCode) {
        countryCode = countryCode.split('-')[1]
        const codePoints = countryCode
            .toUpperCase()
            .split('')
            .map((char) => 127397 + char.charCodeAt())
        return String.fromCodePoint(...codePoints)
    }
}
